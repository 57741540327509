import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useCage } from 'contexts/cage-context';
import { useQueryParams } from 'hooks/useQueryParams';
import type queryString from 'query-string';
import { type ColorValuesDay, ImageFeedbackDto } from 'services/types';

import Loader from '@/components/atoms/Loader';
import { DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import { getLabelByValue } from '@/components/molecules/ShareFalseDetectionDropdown/ShareFalseDetectionDropDownObjects';
import { ToastTemplateBackendFeedback } from '@/components/molecules/ToastTemplate';
import { CombinedFishHealthWithTemperatureData } from '@/components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChartMappers';
import { useCageFilter } from '@/contexts/cage-filter-context';
import { useMe } from '@/contexts/meContext';
import { fullSizeImageMapper, useImageState } from '@/hooks/image-state';
import type { FishHealthData } from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareWounds/components/FishHealthData';
import { CageWelfareWoundsDashboardPageContent } from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareWounds/pages/CageWelfareWoundsDashboard/CageWelfareWoundsDashboardPageContent';
import { CagePageInterface } from '@/routes/Client/Cage/utils/cage-pages-interface';
import {
    useCombinedWelfareController_FullWelfare,
    useFeedbackController_ImageFeedback,
    useLiceController_GetTemperature,
    useWelfareController_allImages,
} from '@/services/hooks';
import tsToString from '@/utils/tsToString';
import ExportDataDropDown from '@/components/molecules/ExportDataDropDown';
import DownloadOptions from '@/components/molecules/ExportDataDropDown/DownloadOptions';

const downloadableData = (
    dailyHealthData: ColorValuesDay[],
    queryParams?: queryString.ParsedQuery<string>
) => {
    const DEFAULT_DAYPART_TAG_FOR_DOWNLOAD = 'day';
    const daypartTagForDownload = queryParams?.d ? queryParams.d : DEFAULT_DAYPART_TAG_FOR_DOWNLOAD;
    const dailyHealthDataForDownload =
        dailyHealthData?.map((el) => ({
            date: el?.day,
            daypartTag: daypartTagForDownload,
            count: el?.count,
            wound: el?.wound?.toFixed(2),
            scaleLoss: el?.skinSpeckles?.toFixed(2),
            startedMaturation: el?.startedMaturation?.toFixed(2),
            fullyMatured: el?.fullyMature?.toFixed(2),
            smallWound: el?.smallWound?.toFixed(2),
            mediumWound: el?.mediumWound?.toFixed(2),
            bigWound: el?.bigWound?.toFixed(2),
            activeWounds: el?.hasActiveWounds?.toFixed(2),
            healingWounds: el?.hasHealingAndNotActiveWounds?.toFixed(2),
            singleWound: el?.oneWound?.toFixed(2),
            moreThanOneWound: el?.moreThanOneWound?.toFixed(2),
            estimatedDowngrade: el?.notSuperior?.toFixed(2),
        })) ?? [];
    // need to send columns names in this format
    const locationInfoColumns = [
        'location.name',
        'location.timezone',
        'location.sWeightPct',
        'cage.name',
    ];
    const dataColumnNames =
        dailyHealthDataForDownload.length > 0 ? Object.keys(dailyHealthDataForDownload[0]) : [];
    const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);
    const allColumnNames = [...locationInfoColumns, ...formattedDataColumnNames];
    return { dailyHealthDataForDownload, allColumnNames };
};

const CageWelfareWoundsDashboardContainer = ({
    clientId,
    locationId,
    cageId,
}: CagePageInterface) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { isTrout } = useCage();
    const { isOptoscaleAdmin, state } = useMe();

    const { healthType } = useParams<{ healthType: string }>();
    const updatedHealthType = healthType ?? (isTrout ? 'maturation' : 'wounds');

    const { timezone, loading: cageLoading, hasColorWelfare, cage } = useCage();
    const { normalizedFrom, normalizedTo, daypartTag, setFilter } = useCageFilter();
    const from = normalizedFrom ? tsToString(normalizedFrom, timezone) : undefined;
    const to = normalizedTo ? tsToString(normalizedTo, timezone) : undefined;

    const { mutateAsync: reportFeedbackAsync } = useFeedbackController_ImageFeedback();

    // Load data
    const { data: fishHealth, isLoading: fishHealthLoading } =
        useCombinedWelfareController_FullWelfare(
            cageId,
            { from: from, to: to, daypartTag: daypartTag },
            { staleTime: 5 * 60 * 1000 }
        );
    // Load list of dates with images
    // color-welfare-image
    const type = hasColorWelfare ? 'color-welfare-image' : 'bw-welfare';

    const { data: temperature } = useLiceController_GetTemperature(cageId, {
        daypartTag: daypartTag,
        weightUnitTag: 'g',
    });

    const combinedFishHealthData = hasColorWelfare ? fishHealth?.data.color : fishHealth?.data.bw;

    const loading = cageLoading || fishHealthLoading;
    const combinedFishHealthWithTemperatureData = combinedFishHealthData
        ? CombinedFishHealthWithTemperatureData({
              combinedFishHealthData: combinedFishHealthData,
              temperature: temperature?.data ?? [],
          })
        : undefined;

    const imageDetections = useWelfareController_allImages(cageId, { type, numberOfImages: '1' });
    const imageState = useImageState({
        loading: imageDetections.isLoading && !imageDetections.error,
        data: imageDetections.data?.data,
        mapper: useCallback(fullSizeImageMapper(cageId), [cageId]),
        queryParam: 'imageId',
        timezone: timezone,
    });

    const onCommitImageFeedbackDto = async (feedbackDto: ImageFeedbackDto) => {
        if (state?.user?.id) {
            feedbackDto = { ...feedbackDto, reportingUserId: state.user.id };
        }

        if (cageId) {
            feedbackDto = { ...feedbackDto, cageId: cageId };
        }

        const { status, data } = await reportFeedbackAsync({ requestBody: feedbackDto });
        if (status) {
            ToastTemplateBackendFeedback({
                content: `Thank you ${
                    state?.user?.firstName ?? ''
                } for contributing to train our AI model on: ${t(getLabelByValue(data.tag) || '')}`,
                toastId: 'detection-feedback',
                httpStatusCode: status,
            });
        }
    };

    const [queryParams] = useQueryParams();
    const { dailyHealthDataForDownload, allColumnNames } = downloadableData(
        fishHealth?.data?.color?.days as ColorValuesDay[],
        queryParams
    );

    if (loading) {
        return <Loader />;
    }

    const handleSetCageFilter = (dateReturn: DateReturnObject) => {
        setFilter({
            from: dateReturn.dateFrom,
            to: dateReturn.dateTo,
        });
    };

    return (
        <>
            <PageTitleRow title={cage?.name} subTitle={t('Wound insight')}>
                <ExportDataDropDown>
                    <DownloadOptions data={dailyHealthDataForDownload} columns={allColumnNames} />
                </ExportDataDropDown>
            </PageTitleRow>

            {!combinedFishHealthWithTemperatureData && fishHealth && loading ? null : (
                <CageWelfareWoundsDashboardPageContent
                    clientId={clientId}
                    locationId={locationId}
                    cageId={cageId}
                    source={fishHealth.data.source as unknown as string}
                    fishHealthData={combinedFishHealthWithTemperatureData as FishHealthData}
                    images={imageState.loading ? [] : imageState.images}
                    imagesLoading={imageState.loading}
                    cageFilterHandler={handleSetCageFilter}
                    inputDateObject={{
                        dateFrom: from,
                        dateTo: to,
                        shouldStick: false,
                    }}
                />
            )}
        </>
    );
};

export default CageWelfareWoundsDashboardContainer;
export { CageWelfareWoundsDashboardContainer };
