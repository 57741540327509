import { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayToString from 'utils/dayToString';
import BlockTitle from '@/components/atoms/BlockTitle';
import UmerBox from '@/components/atoms/UmerBox';
import FishHealthFilter from '@/components/molecules/FishHealthFilter';
import { ImageViewContainer } from '@/components/organisms/ImageViewer';
import { LAKSVEL_FILTER_TAGS } from '@/components/organisms/ImageViewer/ImageMapper';
import { OptoBarChart } from '@/components/organisms/OptoGraphs';
import Options, {
    autoTimeScale,
    categoryScale,
    formatAxsis,
    minMax,
    noDots,
    startYAxisAtZero,
    TypeNames,
    weightAndLengthUnitConverter,
} from '@/components/organisms/OptoGraphs/optionsModifiers';
import {
    defaultBarChartOptions,
    last500LaksvelToBarChartMapper,
} from '@/components/organisms/OptoGraphs/OptoBarChart';
import {
    defaultLineChartOptions,
    OptoMixedChart,
} from '@/components/organisms/OptoGraphs/OptoMixedChart';
import { DailyLaksvelMapper } from '@/components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChartMappers';
import useLaksvel from '@/contexts/laksvel-context';
import {
    fullSizeLaksvelFinImageMapper,
    fullSizeLaksvelImageMapper,
    useImageState,
} from '@/hooks/image-state';
import { LaksvelRouterPageProps } from '@/routes/Client/Cage/pages/Laksvel/LaksvelSubPages/index';
import { DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import useCageFilter from '@/contexts/cage-filter-context';
import styles from '../Laksvel.module.scss';
import DownloadOptions from '@/components/molecules/ExportDataDropDown/DownloadOptions';
import ExportDataDropDown from '@/components/molecules/ExportDataDropDown';

const FinnCondition: FunctionComponent<LaksvelRouterPageProps> = () => {
    const { t } = useTranslation();
    const INDICATOR = 'fin_damage_score';
    const LAKSVEL_PARAMETER = 'fin_damage';
    const { from, to, setFilter } = useCageFilter();
    const {
        loading,
        laksvelData,
        averageLaksvelData,
        laksvelImages,
        laksvelScoreAccumulatedData,
        timezone,
        cageId,
        isOptoscaleAdmin,
    } = useLaksvel();

    const showLaksvelImageviewer =
        !loading &&
        isOptoscaleAdmin &&
        laksvelScoreAccumulatedData?.[LAKSVEL_PARAMETER]?.show_image_viewer;

    const [selectedImageFilterTag, setSelectedImagedFilterTag] = useState<string>(() => {
        if (LAKSVEL_FILTER_TAGS.length <= 0) {
            return LAKSVEL_FILTER_TAGS[0].tag;
        }
        return LAKSVEL_FILTER_TAGS[0].tag;
    });

    const laksvelFilter = (image) => {
        if (selectedImageFilterTag === 'all') {
            return image;
        }
        return image.score === selectedImageFilterTag;
    };
    const imageMapper = isOptoscaleAdmin
        ? fullSizeLaksvelImageMapper
        : fullSizeLaksvelFinImageMapper;
    const {
        dayswithimages,
        images,
        initialImageIndex,
        onAfterTimelineChangeHandler,
        selectedImageDate,
    } = useImageState({
        data: laksvelImages[INDICATOR],
        mapper: useCallback(imageMapper(cageId, INDICATOR), [cageId]),
        loading: loading,
        timezone: timezone,
        queryParam: 'imageId',
    });
    const onFilterGroupChangeHandler = (tag: string) => {
        setSelectedImagedFilterTag(tag);
    };

    const last500Mapped = last500LaksvelToBarChartMapper({
        data: averageLaksvelData,
        inactiveSetLegend: [],
        property: 'fin_damage',
    });

    const hasPercentageOf100OrHigher = laksvelData?.some((dayData) => {
        const attributeData = dayData.fin_damage;
        return (
            attributeData?.score_1?.percent >= 100 ||
            attributeData?.score_2?.percent >= 100 ||
            attributeData?.score_3?.percent >= 100
        );
    });
    const hasPercentageHigherThan1 = laksvelData?.some((dayData) => {
        const attributeData = dayData.deformity;
        return (
            attributeData?.score_1?.percent > 1 ||
            attributeData?.score_2?.percent > 1 ||
            attributeData?.score_3?.percent > 1
        );
    });
    const handleSetCageFilter = (dateReturn: DateReturnObject) => {
        setFilter({
            from: dateReturn.dateFrom,
            to: dateReturn.dateTo,
        });
    };
    const dataForDownload =
        laksvelData?.map((el) => ({
            date: dayToString(el?.day),
            count: el?.cnt,
            fin_status_score_1: el?.fin_damage?.score_1?.percent?.toFixed(2),
            fin_status_score_2: el?.fin_damage?.score_2?.percent?.toFixed(2),
            fin_status_score_3: el?.fin_damage?.score_3?.percent?.toFixed(2),
        })) ?? [];
    const locationInfoColumns = ['location.name', 'location.timezone', 'cage.name'];
    const dataColumnNames = dataForDownload.length > 0 ? Object.keys(dataForDownload[0]) : [];
    const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);
    const allColumnNames = [...locationInfoColumns, ...formattedDataColumnNames];
    return (
        <>
            <div className={styles.laksvelExportButtons}>
                <ExportDataDropDown>
                    <DownloadOptions data={dataForDownload} columns={allColumnNames} />
                </ExportDataDropDown>
            </div>{' '}
            <div className={styles.laksvelPageContentGrid_50_100}>
                <UmerBox>
                    <figure>
                        <OptoBarChart
                            chartData={last500Mapped}
                            chartName={t('Status')}
                            loading={loading}
                            chartOptions={Options(defaultBarChartOptions).modify(
                                categoryScale({
                                    labels: last500Mapped.labels as string[] | undefined,
                                }),
                                weightAndLengthUnitConverter({
                                    type: 'percent',
                                    toUnit: '%',
                                    maximumFractionDigits: 1,
                                    minimumFractionDigits: 1,
                                }),
                                minMax({ min: 0, max: 100 }),
                                formatAxsis({
                                    axsisID: 'y',
                                    tickType: 'percent',
                                    descimals: 0,
                                })
                            )}
                        />
                    </figure>
                </UmerBox>
                <UmerBox>
                    <OptoMixedChart
                        chartData={DailyLaksvelMapper({
                            data: loading ? [] : laksvelData,
                            laksvelAttribute: 'fin_damage',
                        })}
                        actionButtons={{
                            copyAsPng: true,
                            resetZoom: true,
                            downloadAsPng: true,
                            fullScreen: true,
                            timeScaleSwitch: false,
                        }}
                        chartName={t('Development')}
                        chartOptions={Options(defaultLineChartOptions).modify(
                            autoTimeScale({}),
                            weightAndLengthUnitConverter({
                                type: TypeNames.percent,
                                fromUnit: '%',
                                toUnit: '%',
                            }),
                            startYAxisAtZero({}),
                            noDots(),
                            formatAxsis({
                                axsisID: 'y',
                                tickType: TypeNames.percent,
                                descimals: hasPercentageOf100OrHigher ? 0 : 1,
                            }),
                            (options) => {
                                return {
                                    ...options,
                                    scales: {
                                        ...options.scales,
                                        y: {
                                            ...options.scales?.y,
                                            ...(hasPercentageOf100OrHigher ? { max: 100 } : {}),
                                            ...(!hasPercentageHigherThan1
                                                ? { suggestedMin: 0, suggestedMax: 5 }
                                                : {}),
                                        },
                                    },
                                };
                            }
                        )}
                        showDatePicker={true}
                        cageFilterHandler={handleSetCageFilter}
                        inputDateObject={{
                            dateFrom: from,
                            dateTo: to,
                            shouldStick: false,
                        }}
                        loading={loading}
                    />
                </UmerBox>
                {showLaksvelImageviewer && (
                    <UmerBox>
                        <BlockTitle heading={'Detections'} />

                        <div>
                            <ImageViewContainer
                                initialImageIndex={initialImageIndex}
                                images={images.filter(laksvelFilter) ?? []}
                                dayswithimages={dayswithimages}
                                viewerConfig={{
                                    showPois: false,
                                    showLaksvelLabel: true,
                                    showWoundLabel: false,
                                    showHelpText: false,
                                }}
                                isColor={true}
                                onCommitImageFeedbackDto={() => {}}
                                key="laksvelDetections"
                                instanceKey="laksvelDetections"
                                isOptoScaleAdmin={true}
                                onAfterTimelineChangeHandler={onAfterTimelineChangeHandler}
                            />
                        </div>
                        <div className={styles.maturationFilterOptions}>
                            <FishHealthFilter
                                filterStateList={LAKSVEL_FILTER_TAGS}
                                onFilterGroupChange={onFilterGroupChangeHandler}
                            />
                        </div>
                    </UmerBox>
                )}
            </div>
        </>
    );
};

export default FinnCondition;
export { FinnCondition };
