type MqttTopicsVideo = {
    bioscopeStatus: string;
    videoStatus: string;
};

const mqttTopicsVideo = (bioscopeId: number | string): MqttTopicsVideo => {
    return {
        bioscopeStatus: `bioscope/status/${bioscopeId}`,
        videoStatus: `bioscope/livestream/${bioscopeId}/status`,
    };
};

export { mqttTopicsVideo };
