import type React from 'react';
import { useState } from 'react';
import { t } from 'i18next';

import { IconButton } from '@/components/atoms/Buttons';
import { DownloadIcon } from '@/components/atoms/icons';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import { RightSmallLineIconSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { OverflowToDropdownlist } from '@/components/atoms/OverflowToDropdownlist';

import styles from './ExportDataDropDown.module.scss';

export interface ExportDataDropDownProps {
    children?: React.ReactNode;
}

const ExportDataDropDown = ({ children }: ExportDataDropDownProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.exportDataDropDown}>
            <IconButton
                variant={'secondary'}
                buttonText={t('')}
                iconPos={'left'}
                onClick={toggleDropdown}>
                <DownloadIcon size={20} />
                {t('Export')}
                <GenericIcon icon={RightSmallLineIconSVG} />
            </IconButton>
            <div className={`${styles.dropdownContainer} ${isOpen ? styles.open : ''}`}>
                {isOpen && <OverflowToDropdownlist>{children}</OverflowToDropdownlist>}
            </div>
        </div>
    );
};

export default ExportDataDropDown;
export { ExportDataDropDown };
