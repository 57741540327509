import { FunctionComponent, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import UmerBox from '@/components/atoms/UmerBox';
import { localizedDateFormat } from '@/helpers/date';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { UmerBoxElementStatusInfoWithTrend } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend';
import { InformationLineSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { OptoColors } from '@/config/settings';

import PageActionButtons from '@/components/molecules/PageActionButtons';
import ScoreTable from '@/components/molecules/ScoreTable';
import { laksvelToTabularData } from '@/components/molecules/ScoreTable/ScoreTable';
import i18n from 'i18next';
import useCageFilter from '@/contexts/cage-filter-context';
import {
    defaultBarChartOptions,
    OptoBarChart,
    OptoMixedChart,
} from '@/components/organisms/OptoGraphs';
import Options, {
    autoTimeScale,
    categoryScale,
    formatAxsis,
    minMax,
    noDots,
    startYAxisAtZero,
    TypeNames,
    weightAndLengthUnitConverter,
} from '@/components/organisms/OptoGraphs/optionsModifiers';
import { LaksvelFirstImpressionBarChartMapper } from '@/components/organisms/OptoGraphs/OptoBarChart/OptoBarChartMappers';
import { useLaksvel } from '@/contexts/laksvel-context';
import { LaksvelRouterPageProps } from '@/routes/Client/Cage/pages/Laksvel/LaksvelSubPages/index';
import {
    defaultLineChartOptions,
    LaksvelFirstImpressionLineChartMapper,
} from 'components/organisms/OptoGraphs/OptoMixedChart';
import dayToString from 'utils/dayToString';
import { DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import styles from '../Laksvel.module.scss';
import ExportDataDropDown from '@/components/molecules/ExportDataDropDown';
import DownloadOptions from '@/components/molecules/ExportDataDropDown/DownloadOptions';

// This should be moved, refactored with types
const formattedData = (data) => {
    if (!data || typeof data !== 'object' || Object.keys(data).length === 0) {
        return [];
    }
    const keys = Object.keys(data);
    const days = data[keys[0]]?.trend?.map((entry) => entry?.day) || [];

    return days
        .map((day, index) => {
            const dayData: { day: any; [key: string]: any } = { day };

            for (const key of keys) {
                let value = data[key]?.trend?.[index]?.percent ?? null;
                // Set values below 0 to 0, and values above 100 to 100
                if (value !== null) {
                    value = Math.min(Math.max(value, 0), 100);
                }
                dayData[key] = {
                    trend: value,
                };
            }

            return dayData;
        })
        .reverse(); // Reverse the array to fix the order of the days
};

const FirstImpression: FunctionComponent<LaksvelRouterPageProps> = () => {
    const { t } = useTranslation();
    const [activeBox, setActiveBox] = useState<number>(1);
    const { from, to, setFilter } = useCageFilter();
    const {
        loading,
        laksvelAccumulatedDailyCount,
        laksvelData,
        laksvelTrendData,
        laksvelScoreAccumulatedData,
    } = useLaksvel();

    // This ensures 'Last measurement date' and 'Number of measurements' remain stable even if date range is changed later by datepicker
    const stableLaksvelAccumulatedDailyCount = useRef<number>(0);
    const [lastMeasurementDate, setLastMeasurementDate] = useState<string>('');
    useEffect(() => {
        if (stableLaksvelAccumulatedDailyCount.current === 0 && laksvelAccumulatedDailyCount) {
            stableLaksvelAccumulatedDailyCount.current = laksvelAccumulatedDailyCount;
        }
        if (!lastMeasurementDate && laksvelData[0]?.day) {
            setLastMeasurementDate(laksvelData[0].day);
        }
    }, [laksvelAccumulatedDailyCount, laksvelData, lastMeasurementDate]);

    const formattedDataForTrend = formattedData(laksvelTrendData);

    const handleSetCageFilter = (dateReturn: DateReturnObject) => {
        setFilter({
            from: dateReturn.dateFrom,
            to: dateReturn.dateTo,
        });
    };

    const firstImnpressionBarChartDataset = LaksvelFirstImpressionBarChartMapper(
        laksvelScoreAccumulatedData ?? {}
    );

    const laksvelScoreAccumulatedDataForDownload = [
        Object.entries(laksvelScoreAccumulatedData).reduce(
            (acc, [key, value]) => {
                acc[`${key}_score_1`] = `${(value?.score_1?.percent ?? 0).toFixed(1)}%`;
                acc[`${key}_score_2`] = `${(value?.score_2?.percent ?? 0).toFixed(1)}%`;
                acc[`${key}_score_3`] = `${(value?.score_3?.percent ?? 0).toFixed(1)}%`;
                acc[`${key}_total`] = `${(value?.sum_score1_score2_score3_percent ?? 0).toFixed(
                    1
                )}%`;
                return acc;
            },
            {
                date: dayToString(lastMeasurementDate),
            }
        ),
    ];

    const locationInfoColumns = ['location.name', 'location.timezone', 'cage.name'];

    const dataColumnNames =
        laksvelScoreAccumulatedDataForDownload.length > 0
            ? Object.keys(laksvelScoreAccumulatedDataForDownload[0])
            : [];
    const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);
    const allColumnNames = [...locationInfoColumns, ...formattedDataColumnNames];

    return (
        <div className={styles.pageBoxLayout}>
            <UmerBox doublePadding={true}>
                <div className={styles.switchingButtons}>
                    <button
                        type="button"
                        onClick={() => setActiveBox(1)}
                        className={activeBox === 1 ? styles.activeButton : ''}>
                        {t("Today's status")}
                    </button>
                    <button
                        type="button"
                        onClick={() => setActiveBox(2)}
                        className={activeBox === 2 ? styles.activeButton : ''}>
                        {t("Today's status, bar chart")}
                    </button>
                    <button
                        type="button"
                        onClick={() => setActiveBox(3)}
                        className={activeBox === 3 ? styles.activeButton : ''}>
                        {t('Trend chart')}
                    </button>
                </div>
                <div className={styles.laksvelPageDashBoardGrid_100}>
                    {activeBox === 1 && (
                        <>
                            <div className="d-flex justify-content-between">
                                <h3>{t('Welfare parameters')}</h3>
                                <ExportDataDropDown>
                                    <DownloadOptions
                                        data={laksvelScoreAccumulatedDataForDownload}
                                        columns={allColumnNames}
                                    />
                                </ExportDataDropDown>
                            </div>
                            <ScoreTable
                                data={laksvelToTabularData(laksvelScoreAccumulatedData ?? null)}
                            />
                        </>
                    )}
                    {activeBox === 2 && (
                        <OptoBarChart
                            chartData={firstImnpressionBarChartDataset}
                            chartName={t('Today’s status, bar chart')}
                            loading={loading}
                            chartOptions={Options(defaultBarChartOptions).modify(
                                categoryScale({
                                    labels: firstImnpressionBarChartDataset.labels,
                                }),
                                weightAndLengthUnitConverter({
                                    type: 'percent',
                                    toUnit: '%',
                                    maximumFractionDigits: 1,
                                    minimumFractionDigits: 1,
                                }),
                                formatAxsis({
                                    axsisID: 'y',
                                    tickType: 'percent',
                                    descimals: 0,
                                })
                            )}
                        />
                    )}
                    {activeBox === 3 && (
                        <OptoMixedChart
                            chartName={t('Trend')}
                            chartData={LaksvelFirstImpressionLineChartMapper({
                                data: formattedDataForTrend,
                            })}
                            actionButtons={{
                                copyAsPng: true,
                                resetZoom: true,
                                downloadAsPng: true,
                                fullScreen: true,
                                timeScaleSwitch: false,
                            }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale({}),
                                weightAndLengthUnitConverter({
                                    type: TypeNames.percent,
                                    fromUnit: '%',
                                    toUnit: '%',
                                }),
                                startYAxisAtZero({}),
                                noDots(),
                                minMax({ min: 0, max: 100 }),
                                formatAxsis({
                                    axsisID: 'y',
                                    tickType: TypeNames.percent,
                                    descimals: 0,
                                })
                            )}
                            loading={loading}
                            showDatePicker={true}
                            cageFilterHandler={handleSetCageFilter}
                            inputDateObject={{
                                dateFrom: from,
                                dateTo: to,
                                shouldStick: false,
                            }}
                        />
                    )}
                </div>
            </UmerBox>
            <UmerBox doublePadding={true}>
                <div className={styles.rightSideBoxContainer}>
                    <UmerBoxElementHeader
                        headerText={t('Information')}
                        headerIcon={InformationLineSVG}
                        headerIconColor={OptoColors.OceanGreen}
                    />
                    <div className={styles.oneColumn}>
                        <UmerBoxElementStatusInfoWithTrend
                            title={t('Number of measurements')}
                            info={String(stableLaksvelAccumulatedDailyCount.current)}
                        />
                        <UmerBoxElementStatusInfoWithTrend
                            title={t('Last measurement day')}
                            info={localizedDateFormat({
                                dateString: lastMeasurementDate,
                                locale: i18n.language,
                            })}
                        />
                    </div>
                </div>
            </UmerBox>
        </div>
    );
};

export default FirstImpression;
export { FirstImpression };
