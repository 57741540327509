import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useQueryParams from 'hooks/useQueryParams';
import i18n, { t } from 'i18next';

import { IconButton } from '@/components/atoms/Buttons';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import {
    ArrowRightSVG,
    BalanceFillSVG,
    GrowthIconSVG,
    TargetLineSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { UmerBox } from '@/components/atoms/UmerBox';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import ExportDataDropDown from '@/components/molecules/ExportDataDropDown';
import DownloadOptions from '@/components/molecules/ExportDataDropDown/DownloadOptions';
import { GenericInfoBox } from '@/components/molecules/GenericInfoBox';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import WeightStatus from '@/components/molecules/WeightStatus/WeightStatus';
import useCage from '@/contexts/cage-context';
import { useMe } from '@/contexts/meContext';
import { createCageUrl } from '@/helpers/urlGenerator';
import { useRestFormattedOverview } from '@/routes/Client/Cage/components/cage-formatted-overview-context';
//
// import BioscopeStatusBox from './components/BioscopeStatusBox';
// import OverviewTour from './components/OverviewTour';
import { useRestOverview } from '@/routes/Client/Cage/components/cage-overview-context';
import { localeFormatNumber } from '@/utils/decimalNumberFormatter';

import formattedWeightUnit from '../../../../../../utils/formattedWeightUnit';

import LivingWeightGraph from './components/LivingWeightGraph';

import styles from './CageWeightDashboard.module.scss';

const DEFAULT_DAYPART_TAG_FOR_DOWNLOAD = 'day';

interface CageWeightDashboardPageContentProps {
    clientId: number;
    locationId: number;
    cageId: number;
}

function CageWeightDashboardPageContent({
    clientId,
    locationId,
    cageId,
}: CageWeightDashboardPageContentProps) {
    const { loading, error, data: overview, weightUnitTag } = useRestOverview();
    const formattedOverview = useRestFormattedOverview();
    const history = useHistory();
    const { cage, client } = useCage();
    const { isOptoscaleAdmin } = useMe();

    //add following feature flag in clients table in db to enable this feature for any client
    /**
     * Checks if the "LIVING_WEIGHT_ALL_TAGS_ENABLED" feature flag is enabled for the client.
     *
     * @constant
     * @type {boolean}
     * @default false
     * @remarks
     * This flag determines whether the living weight for all tags feature is enabled.
     * It checks the client's feature flags array for the presence of the "LIVING_WEIGHT_ALL_TAGS_ENABLED" flag.
     * If the client or featureFlags is undefined, it defaults to false.
     * You can add this feature flag to the client's feature flags array in the database to enable this feature for the client.
     */
    const hasLivingWeightAllTagsEnabled =
        client?.featureFlags?.includes('LIVING_WEIGHT_ALL_TAGS_ENABLED') ?? false;

    const [estimatedWeight, setEstimatedWeight] = useState<string>('');
    const [estimatedWeightUnit, setEstimatedWeightUnit] = useState<string>('');

    const [growth, setGrowth] = useState<string>('');
    const [growthUnit, setGrowthUnit] = useState<string>('');

    const queryParams = useQueryParams();
    const daypartTag = queryParams[0]?.d ? queryParams[0].d : DEFAULT_DAYPART_TAG_FOR_DOWNLOAD;
    const dailyOverview = overview?.days;
    const dailyOverviewForDownload =
        dailyOverview?.map((el) => ({
            date: el?.day,
            daypartTag: el?.daypartTag,
            measurementsCount: el?.data.measCnt,
            weightAvg: el?.data.weightAvg?.toFixed(2),
            weightTrend: el?.data.weightTrend?.toFixed(2),
            weightGrowthAvg: el?.data.weightGrowthAvg?.toFixed(2),
            weightGrowthTrend: el?.data.weightGrowthTrend?.toFixed(2),
        })) ?? [];
    const filteredByDayPartTag =
        dailyOverviewForDownload.filter((item) => item?.daypartTag === daypartTag) ?? [];

    // need to send columns names in this format
    const locationInfoColumns = [
        'location.name',
        'location.timezone',
        'location.sWeightPct',
        'cage.name',
    ];
    const dataColumnNames =
        dailyOverviewForDownload.length > 0 ? Object.keys(dailyOverviewForDownload[0]) : [];
    const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);
    const allColumnNames = [...locationInfoColumns, ...formattedDataColumnNames];

    useEffect(() => {
        if (overview) {
            const { value, weightUnit } = formattedWeightUnit(
                overview.estimated_weight,
                weightUnitTag,
                0,
                false
            );

            setEstimatedWeight(value);
            setEstimatedWeightUnit(weightUnit);
        }
    }, [overview]);

    useEffect(() => {
        if (formattedOverview && !formattedOverview.loading && formattedOverview.data) {
            setGrowth(
                localeFormatNumber(
                    parseFloat(formattedOverview.data.weightGrowth),
                    1,
                    i18n.language,
                    '-'
                )
            );
            setGrowthUnit(`${t(`weightUnitTag.${weightUnitTag}`)}${t('/day')}`);
        }
    }, [formattedOverview]);

    return (
        <>
            <PageTitleRow title={cage?.name} subTitle={t('Weight')}>
                <ExportDataDropDown>
                    <DownloadOptions data={filteredByDayPartTag} columns={allColumnNames} />
                </ExportDataDropDown>
            </PageTitleRow>

            <div className={styles.infoItemTopBar}>
                <GenericInfoBox
                    title={t('Living weight')}
                    icon={BalanceFillSVG}
                    circleColor={'#FFD479'}
                    info={estimatedWeight}
                    unit={estimatedWeightUnit}
                    shouldHaveTooltip={true}
                    tooltipText={t('EstimatedLivingWeight')}
                />
                <GenericInfoBox
                    title={t('Growth')}
                    icon={GrowthIconSVG}
                    circleColor={'#696999'}
                    info={growth}
                    unit={growthUnit}
                    shouldHaveTooltip={true}
                    tooltipText={t('descriptions.weightAndGrowthGrowth')}
                />
                <GenericInfoBox title={t('bFCR')} icon={TargetLineSVG} circleColor={'#5BB784'} />
            </div>

            <div className={styles.pageBoxLayout}>
                <UmerBox>
                    <LivingWeightGraph
                        isOptoscaleAdmin={isOptoscaleAdmin}
                        hasLivingWeightAllTagsEnabled={hasLivingWeightAllTagsEnabled}
                    />
                </UmerBox>

                <UmerBox doublePadding={true}>
                    <UmerBoxElementHeader headerText={t('Weight and growth')}>
                        <IconButton
                            variant={'secondary'}
                            onlyIcon={true}
                            iconPos={'right'}
                            onClick={() =>
                                history.push(
                                    `${createCageUrl(clientId, locationId, cageId)}/weight/graphs`
                                )
                            }>
                            <GenericIcon icon={ArrowRightSVG} size={20}></GenericIcon>
                        </IconButton>
                    </UmerBoxElementHeader>
                    <div className={styles.weightStatusContainer}>
                        <WeightStatus
                            formattedOverview={formattedOverview}
                            weightUnit={weightUnitTag}
                        />
                    </div>
                </UmerBox>
            </div>
        </>
    );
}

export default CageWeightDashboardPageContent;
