import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Alert, InputGroup, InputGroupText, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useUserController_Login } from 'services/hooks';
import GenericIcon from '@/components/atoms/icons/GenericIcon';
import { MailIconSVG, LockIconSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { Button } from 'components/atoms/Buttons';
import Loader from 'components/atoms/Loader';
import ChangeLanguage from 'components/molecules/ChangeLanguage/ChangeLanguage';

import '../public.scss';
import styles from './Login.module.scss';

// Define auth provider types
interface AuthProvider {
    name: string;
    isEnforced: boolean;
    isDefault: boolean;
}

interface AuthOptions {
    organization: {
        id: string;
        name: string;
    };
    authProviders: AuthProvider[];
}

export const Login2: React.Factory<{}> = () => {
    const [step, setStep] = React.useState<'email' | 'provider' | 'password'>('email');
    const [values, setValues] = React.useState({
        email: '',
        password: '',
    });
    const [authOptions, setAuthOptions] = React.useState<AuthOptions | null>(null);
    const [selectedProvider, setSelectedProvider] = React.useState<string | null>(null);
    const [checkingEmail, setCheckingEmail] = React.useState(false);
    const [emailError, setEmailError] = React.useState<string | null>(null);

    const { search } = useLocation();
    const query = new URLSearchParams(search);

    const {
        mutate: login,
        isLoading: loading,
        error,
    } = useUserController_Login({
        onSuccess: () => {
            queryClient.invalidateQueries();
            window.location.replace(query.get('redirectTo') || '/');
        },
    });

    const { t } = useTranslation();

    const queryClient = useQueryClient();

    const errorMessage = (error?.message as any)?.message; //the code generator is broken - it does not handle replies that are JSON objects

    // Handle error messages from the callback
    React.useEffect(() => {
        const error = query.get('error');
        if (error) {
            switch (error) {
                case 'admin_consent_required':
                    setEmailError(
                        t(
                            'This application requires admin consent. Please contact your organization administrator.'
                        )
                    );
                    break;
                case 'unauthorized_tenant':
                    setEmailError(
                        t('Your organization is not authorized to use this application.')
                    );
                    break;
                case 'invite_only':
                    setEmailError(
                        t(
                            'This application is invite-only. Please contact your administrator for access.'
                        )
                    );
                    break;
                case 'auth_failed':
                    setEmailError(t('Authentication failed. Please try again.'));
                    break;
                case 'invalid_state':
                    setEmailError(t('Invalid authentication state. Please try again.'));
                    break;
                case 'token_acquisition_failed':
                    setEmailError(t('Failed to acquire authentication token. Please try again.'));
                    break;
                case 'invalid_token':
                    setEmailError(t('Invalid authentication token. Please try again.'));
                    break;
                case 'callback_failed':
                    setEmailError(t('Authentication callback failed. Please try again.'));
                    break;
                default:
                    setEmailError(t('An unexpected error occurred. Please try again.'));
            }
        }
    }, [query, t]);

    const handleEmailSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!values.email) return;

        setCheckingEmail(true);
        setEmailError(null);

        try {
            // Call API to check email domain and get available providers
            const response = await fetch('/api/v2/auth/check-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: values.email }),
            });

            if (!response.ok) {
                const errorData = await response.json().catch(() => null);
                if (response.status === 400) {
                    setEmailError(errorData?.message || t('Invalid email format'));
                } else if (response.status === 404) {
                    setEmailError(t('Domain not found or not associated with any organization'));
                } else {
                    setEmailError(t('Failed to verify email domain'));
                }
                return;
            }

            const data = await response.json();
            setAuthOptions(data);

            // If there's an enforced provider, automatically use it
            // const enforcedProvider = data.authProviders.find((p: AuthProvider) => p.isEnforced);
            // if (enforcedProvider) {
            //     handleProviderLogin(enforcedProvider.name);
            //     return;
            // }

            // If there's only one provider and it's not local auth, use it directly
            // if (data.authProviders.length === 1 && data.authProviders[0].name !== 'local') {
            //     handleProviderLogin(data.authProviders[0].name);
            //     return;
            // }

            // If local auth is the only or default option, go to password step
            const localAuth = data.authProviders.find((p: AuthProvider) => p.name === 'local');
            if (localAuth && (data.authProviders.length === 1 || localAuth.isDefault)) {
                setStep('password');
                return;
            }

            // Otherwise, show provider selection
            setStep('provider');
        } catch (error) {
            console.error('Error checking email:', error);
            setEmailError(t('An unexpected error occurred. Please try again.'));
        } finally {
            setCheckingEmail(false);
        }
    };

    const handleProviderLogin = (provider: string) => {
        setSelectedProvider(provider);

        if (provider === 'local') {
            setStep('password');
            return;
        }

        // Redirect to the appropriate SSO provider
        window.location.href = `/api/v2/auth/${provider}/login?email=${encodeURIComponent(
            values.email
        )}&redirectTo=${encodeURIComponent(query.get('redirectTo') || '/')}`;
    };

    const handlePasswordLogin = (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        login({
            queryParams: {
                username: values.email,
                password: values.password,
            },
        });
    };

    const renderEmailStep = () => (
        <Form onSubmit={handleEmailSubmit}>
            <FormGroup>
                <Label className="text-white">{t('Email')}</Label>
                <InputGroup className={styles.inputGroup}>
                    <InputGroupText className={styles.inputGroupText}>
                        <GenericIcon icon={MailIconSVG} />
                    </InputGroupText>
                    <Input
                        type="email"
                        name="email"
                        placeholder={t('Email')}
                        value={values.email}
                        className="formControl"
                        onChange={(e) => {
                            setValues({
                                ...values,
                                email: e.target.value,
                            });
                            if (emailError) setEmailError(null);
                        }}
                        disabled={checkingEmail}
                    />
                </InputGroup>
            </FormGroup>
            {emailError && (
                <Alert color="danger" className="mt-2">
                    {emailError}
                </Alert>
            )}
            <div className="mt-2 d-flex justify-content-end">
                {checkingEmail ? (
                    <div className="w-100 d-flex justify-content-center flex-column ml-3">
                        <Loader size={'250px'} />
                    </div>
                ) : (
                    <Button size={'large'} type="submit" disabled={!values.email}>
                        {t('Continue')}
                    </Button>
                )}
            </div>
        </Form>
    );

    const renderProviderSelection = () => (
        <div className="auth-provider-selection">
            <div className={styles.providerList}>
                {authOptions?.authProviders.map((provider) => (
                    <Button
                        key={provider.name}
                        size={'large'}
                        className={`mt-2 provider-btn provider-${provider.name.toLowerCase()}`}
                        onClick={() => handleProviderLogin(provider.name)}>
                        {provider.name === 'entra' &&
                            `${t('Sign in with')} Microsoft ${t('work account')}`}
                        {provider.name === 'google' && `${t('Sign in with')} Google`}
                        {provider.name === 'local' &&
                            `${t('Sign in with')} ${t('Email & Password')}`}
                    </Button>
                ))}
            </div>
            <div className="mt-3 text-center">
                <button className="btn btn-link text-white" onClick={() => setStep('email')}>
                    {t('Use a different email')}
                </button>
            </div>
        </div>
    );

    const renderPasswordStep = () => (
        <Form onSubmit={handlePasswordLogin}>
            <FormGroup>
                <Label className="text-white">{t('Email')}</Label>
                <InputGroup className={styles.inputGroup}>
                    <InputGroupText className={styles.inputGroupText}>
                        <GenericIcon icon={MailIconSVG} />
                    </InputGroupText>
                    <Input
                        type="email"
                        name="email"
                        value={values.email}
                        className="formControl"
                        disabled
                    />
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <Label className="text-white">{t('Password')}</Label>
                <InputGroup className={styles.inputGroup}>
                    <InputGroupText className={styles.inputGroupText}>
                        <GenericIcon icon={LockIconSVG} />
                    </InputGroupText>
                    <Input
                        type="password"
                        name="password"
                        placeholder={t('Password')}
                        value={values.password}
                        className="formControl"
                        onChange={(e) =>
                            setValues({
                                ...values,
                                password: e.target.value,
                            })
                        }
                    />
                </InputGroup>
            </FormGroup>
            <div className="mt-2 d-flex justify-content-center">
                {loading ? (
                    <div className="w-100 d-flex justify-content-center flex-column ml-3">
                        <Loader size={'250px'} />
                    </div>
                ) : (
                    <Button
                        size={'large'}
                        type="submit"
                        className="btn-block"
                        disabled={!values.password}>
                        {t('Login')}
                    </Button>
                )}
            </div>
            <div className="mt-3 text-center">
                <button className="btn btn-link text-white" onClick={() => setStep('email')}>
                    {t('Use a different email')}
                </button>
            </div>
            <div className="mt-2 text-center clearfix">
                <Link to="/reset-password-request2">{t('Reset password')}</Link>
            </div>
        </Form>
    );

    return (
        <div className={styles.loginContainer}>
            <video autoPlay loop muted className={styles.backgroundVideo}>
                <source src="./loginVideoBg.mp4" type="video/mp4" />
            </video>

            <div className={styles.loginRow}>
                <div className={styles.leftColumn}>
                    <div className={styles.overlayContent}>
                        <h1>{t('Discover Precision')}</h1>
                        <p>{t('Get full control of fish weight...')}</p>
                    </div>
                </div>
                <div className={styles.rightColumn}>
                    <div className={styles.topSection}>
                        <img src="/logo.png" alt="" className={styles.logo} />
                        <div className={styles.languageBox}>
                            <ChangeLanguage />
                        </div>
                    </div>
                    <div className={styles.loginBox}>
                        <h2>{t('Hello again!')}</h2>
                        <h3>{t('Sign in to your account')}</h3>
                        {step === 'email' && renderEmailStep()}
                        {step === 'provider' && renderProviderSelection()}
                        {step === 'password' && renderPasswordStep()}

                        {step !== 'email' && errorMessage ? (
                            <Alert color="danger" className="mt-3">
                                {errorMessage}
                            </Alert>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
