import React, { useMemo } from 'react';
import { useCage } from 'contexts/cage-context';
import i18n, { t } from 'i18next';

import { UmerBox } from '@/components/atoms/UmerBox';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { UmerBoxElementStatusInfoWithTrend } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend';
import { UmerBoxElementText } from '@/components/atoms/UmerBoxElements/UmerBoxElementText';
import { DatePicker, DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import { localizedDateFormat } from '@/helpers/date';
import { localeFormatNumber } from '@/utils/decimalNumberFormatter';
import { Loader } from 'components/atoms/Loader';
import { ExportDataDropDown } from 'components/molecules/ExportDataDropDown';
import NoData from 'components/NoData';
import SomethingHappened from 'components/SomethingHappened';

import WeightDistributionHistogramPlayer from './components/WeightDistributionHistogramPlayer';
import useWeightDistribution from './cage-weight-distribution-context';

import styles from './CageWeightDistribution.module.scss';
import DownloadOptions from '@/components/molecules/ExportDataDropDown/DownloadOptions';

interface CageWeightDistributionProps {
    cageFilterHandler?: (data: DateReturnObject) => void;
    inputDateObject?: DateReturnObject;
}

function CageWeightDistribution({
    cageFilterHandler,
    inputDateObject,
}: CageWeightDistributionProps) {
    const { loading, error, days, day, weightUnitTag, datum } = useWeightDistribution();

    const { cage } = useCage();
    if (error) {
        return <SomethingHappened error={error} />;
    }

    if (loading) {
        return <Loader />;
    }

    if (!Array.isArray(days) || days.length < 1) {
        return <NoData />;
    }
    const allData =
        days?.flatMap((el) => {
            return el.histograms.flatMap((x) => ({
                date: el?.day,
                daily_Distribution_Type_Tag: x?.dailyDistributionTypeTag,
                bin_from: `${x?.bucket.from}g`,
                bin_to: `${x?.bucket.to}g`,
                cnt: x?.cnt,
                cntPct: x?.cntPct,
            }));
        }) ?? [];

    const { weight, cv } = useMemo(() => {
        const { weight, cv } = datum ?? {};

        let cvText = '-';
        if (cv) {
            cvText = `${localeFormatNumber(cv, 1, i18n.language)}%`;
        }

        const localizedWeightNumber = localeFormatNumber(weight, 0, i18n.language, '-');
        let weightText = '-';
        if (weight) {
            weightText = `${localizedWeightNumber} ${weightUnitTag}`;
        }

        return {
            cv: cvText,
            weight: weightText,
        };
    }, [datum, weightUnitTag]);

    // need to send columns names in this format
    const locationInfoColumns = ['location.name', 'location.timezone', 'cage.name'];
    const dataColumnNames = allData.length > 0 ? Object.keys(allData[0]) : [];
    const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);
    const allColumnNames = [...locationInfoColumns, ...formattedDataColumnNames];

    return (
        <>
            <PageTitleRow title={cage?.name} subTitle={t('Weight distribution')}>
                <ExportDataDropDown>
                    <DownloadOptions data={allData} columns={allColumnNames} />
                </ExportDataDropDown>
            </PageTitleRow>
            <div className={styles.datePickerContainer}>
                <DatePicker
                    onDateChange={cageFilterHandler}
                    fromDefault={inputDateObject.dateFrom}
                    toDefault={inputDateObject.dateTo}
                />
            </div>
            <div className={styles.pageBoxLayout}>
                <UmerBox doublePadding={true}>
                    <WeightDistributionHistogramPlayer />
                </UmerBox>
                <UmerBox doublePadding={true}>
                    <div className={styles.rightSideBoxContainer}>
                        <UmerBoxElementHeader headerText={t('Living weight')} />
                        <UmerBoxElementText
                            text={`${t('Date')} ${localizedDateFormat({
                                dateString: day,
                                onlydate: true,
                                locale: i18n.language,
                            })}`}
                        />
                        <UmerBoxElementStatusInfoWithTrend
                            title={t('Living weight')}
                            info={weight}
                        />
                        <UmerBoxElementStatusInfoWithTrend title={t('CV')} info={cv} />
                    </div>
                </UmerBox>
            </div>
        </>
    );
}

export default CageWeightDistribution;
