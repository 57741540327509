import React, { useEffect, useReducer, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';

import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import Loader from '@/components/atoms/Loader/Loader';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import * as icons from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { IconButton } from '@/components/atoms/Buttons/IconButton/IconButton';
import { GlassHourIcon } from '@/components/atoms/icons/GlassHourIcon/GlassHourIcon';
import { ReloadIcon } from '@/components/atoms/icons/ReloadIcon/ReloadIcon';
import { ErrorAlertIcon } from '@/components/atoms/icons/ErrorAlertIcon/ErrorAlertIcon';
import { ViewModeIcon } from '@/components/atoms/icons/ViewModeIcon/ViewModeIcon';
import { VideoIcon } from '@/components/atoms/icons/VideoIcon/VideoIcon';
import { SkeletonLoader } from '@/components/atoms/SkeletonLoader/SkeletonLoader';
import { OptoColors } from '@/config/settings';
import { UmerBox } from '@/components/atoms/UmerBox';
import { VideoLoadingTopBaner } from '@/components/molecules/VideoLoadingTopBaner';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import VideoBox from '@/components/molecules/VideoBox';
import SomethingHappened from '@/components/SomethingHappened';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import { useMe } from '@/contexts/meContext';
import { useMqtt } from '@/contexts/mqtt-provider-context';
import {
    useClientController_Overview,
    useVideoStreamController_startStream,
    useVideoStreamController_stopStream,
    useVideoStreamController_getBioscopeQuotaCheck,
    useVideoStreamController_getLocationStreamsStatus,
    useVideoStreamController_disableBioscopeStream,
} from '@/services/hooks';
import type { OverviewCage } from '@/services/types';
import videoStreamImage from 'svg/vidoe-stream.jpg';
import { mqttTopicsVideo } from './topics';
import styles from './LocationVideo.module.scss';
import VideoLoaderSkeleton from '@/components/atoms/VideoLoaderSkeleton';
import { ErrorState } from '@/components/molecules/VideoBox/VideoBox.stories';
import { Button } from '@/components/atoms/Buttons';
import StickyAlert from '@/components/molecules/StickyAlert';
import { CommentsController_CreateComment } from '@/services/services';

const TIMEOUT = 5000;
interface OverviewCageWithMuxData extends OverviewCage {
    muxData?: any;
    online: boolean | undefined;
    bioscopeId: number;
    isButtonDisabled: boolean;
    icon: 'Loading' | 'Streaming' | 'Error' | 'Offline' | 'Online';
    isButtonSelected: boolean;
    isOutOfQuota: boolean;
    dailyQuotaAvailable?: any;
    stream?: any;
}

type AllButtonState = {
    isSelected: boolean;
    isDisabled: boolean;
};

interface LocationVideoState {
    activeCages: OverviewCageWithMuxData[];
    locationName: string;
    onlyViewMode: boolean;
    allButtonState: AllButtonState;

    initializeCages: (activeCages, startStream, stopStream, disableStream) => void;
    updateBioscopeOnlineStatus: (bioscopeId, status) => void;
    buttonActionHandler: (bioscopeId, value?) => void;
    startStream: (bioscopeId) => void;
    stopStream: (bioscopeId) => void;
    disableStream: (bioscopeId) => void;
    updateMuxData: (bioscopeId, muxData) => void;
    updateAllButtonState: () => void;
    timetest: (bioscopeId, fn) => void;
    setOnlyViewMode: (onlyViewMode) => void;
    setIsButtonDisabled: (bioscopeId, isButtonDisabled) => void;
}

const useLocationVideoStore = create<LocationVideoState>()(
    immer((set) => ({
        activeCages: [] as OverviewCageWithMuxData[],
        locationName: '',
        onlyViewMode: false,
        allButtonState: { isSelected: false, isDisabled: false } as AllButtonState,
        startStream: (bioscopeId) => {},
        stopStream: (bioscopeId) => {},
        disableStream: (bioscopeId) => {},
        timetest: (bioscopeId) => {},

        initializeCages: (activeCages, startStream, stopStream, disableStream) =>
            set((state) => {
                state.startStream = startStream;
                state.stopStream = stopStream;
                state.disableStream = disableStream;
                state.activeCages = activeCages.map((cage) => ({
                    ...cage,
                    online: undefined,
                    isButtonDisabled: true,
                    isLoading: false,
                    icon: 'Offline',
                    isButtonSelected: cage.muxData?.status === 'active',
                }));
            }),

        setIsButtonDisabled: (bioscopeId, isButtonDisabled) =>
            set((state) => {
                if (bioscopeId === 0) {
                    state.allButtonState.isDisabled = isButtonDisabled;
                } else {
                    const cage = state.activeCages.find((c) => c.bioscopeId === bioscopeId);
                    if (cage) {
                        cage.isButtonDisabled = isButtonDisabled;
                    }
                }
            }),

        setOnlyViewMode: (onlyViewMode) =>
            set((state) => {
                console.log('setOnlyViewMode:', onlyViewMode);
                state.onlyViewMode = onlyViewMode;
            }),

        updateBioscopeOnlineStatus: (bioscopeId, status) =>
            set((state) => {
                const cage = state.activeCages.find((c) => c.bioscopeId === bioscopeId);
                if (status === 'online' && cage?.dailyQuotaAvailable?.quotaAvailable) {
                    cage.online = status === 'online';
                    cage.isButtonDisabled = false;
                } else if (status === 'online' && !cage?.dailyQuotaAvailable?.dailyQuotaAvailable) {
                    cage.online = status === 'online';
                    cage.isButtonDisabled = true;
                    cage.isOutOfQuota = true;
                } else if (status === 'offline') {
                    cage.online = false;
                    cage.isButtonDisabled = true;
                }
            }),

        buttonActionHandler: (bioscopeId, v?) =>
            set((state) => {
                const cage = state.activeCages.find((c) => c.bioscopeId === bioscopeId);
                if (cage) {
                    if (typeof v !== 'undefined') {
                        cage.isButtonSelected = v;
                    } else {
                        cage.isButtonSelected = !cage.isButtonSelected;
                    }
                    if (cage.isButtonSelected === true) {
                        state.startStream({ bioscopeId: bioscopeId });
                    } else if (cage.isButtonSelected === false) {
                        state.stopStream({ bioscopeId: bioscopeId });
                    }
                }
            }),

        updateMuxData: (bioscopeId, muxData) =>
            set((state) => {
                const cage = state.activeCages.find((c) => c.bioscopeId === bioscopeId);
                if (cage) {
                    cage.muxData = muxData;
                }
            }),

        updateAllButtonState: () =>
            set((state) => {
                const flag = state.activeCages
                    .filter((cage) => cage.online && !cage.isOutOfQuota)
                    .every((cage) => cage.isButtonSelected);
                state.allButtonState.isSelected = flag;
            }),
    }))
);

const LocationVideoContainer = ({ clientId, locationId }) => {
    const { t } = useTranslation();
    const user_id = useMe()?.state?.user?.id ?? null;
    const { client, isConnected, subscribe, unsubscribe } = useMqtt();
    const [flag, toggleFlag] = useState(false);

    const hasRun0 = useRef(false);
    const hasRun1 = useRef(false);
    const hasRun2 = useRef(false);
    const hasRun3 = useRef(false);

    const { mutateAsync: startStream } = useVideoStreamController_startStream();
    const { mutateAsync: stopStream } = useVideoStreamController_stopStream();
    const { mutateAsync: disableStream } = useVideoStreamController_disableBioscopeStream();

    const { data: locationStreams } = useVideoStreamController_getLocationStreamsStatus(locationId);
    const {
        activeCages,
        initializeCages,
        updateBioscopeOnlineStatus,
        buttonActionHandler,
        updateMuxData,
        allButtonState,
        updateAllButtonState,
        onlyViewMode,
        setOnlyViewMode,
        setIsButtonDisabled,
    } = useLocationVideoStore();

    const activeCagesRef = useRef(activeCages);

    useEffect(() => {
        activeCagesRef.current = activeCages;

        locationStreams && console.log('locationStreams:', locationStreams);

        if (!hasRun0.current && activeCages && activeCages.length > 0) {
            hasRun0.current = true;
            const isAnotherUserStreaming = activeCages.some(
                (cage) =>
                    cage.muxData?.userId &&
                    cage.muxData.userId !== user_id &&
                    cage.muxData?.status !== 'idle'
            );

            setOnlyViewMode(isAnotherUserStreaming);
        }

        if (locationStreams?.data && !hasRun1.current) {
            hasRun1.current = true;
            initializeCages(locationStreams?.data, startStream, stopStream, disableStream);

            if (locationStreams?.data) {
                const cagesArray = Array.isArray(locationStreams?.data) ? locationStreams.data : [];
                for (const cage of cagesArray) {
                    if (cage.stream?.status === 'disconnected') {
                        disableStream({ bioscopeId: cage.bioscopeId });
                    }
                }
            }

            if (Array.isArray(locationStreams?.data) && locationStreams.data.length > 0) {
                for (let i = 0; i < locationStreams.data.length; i++) {
                    const bioscope = locationStreams.data[i];
                    const stream = bioscope.stream;

                    stream &&
                        updateMuxData(stream.bioscope_id, {
                            streamId: stream.mux_stream_id,
                            status: stream.status,
                            timestamp: stream.timestamp,
                            userId: stream.started_by_user_id || null,
                            playbackId: stream.playback_id || null,
                        });

                    if (stream?.status === 'active') {
                        buttonActionHandler(stream.bioscope_id, true);
                    }
                }
            }
        }

        if (!hasRun2.current && isConnected && client && activeCages.length > 0) {
            hasRun2.current = true;
            const handleMQTTMessage = async (topic: string, message: Buffer) => {
                const extractBioscopeIdFromTopic = (topic: string): number | null => {
                    const match = topic.match(/\/(\d+)\//);
                    return match ? parseInt(match[1], 10) : null;
                };
                console.log('topic:', topic, 'msg', message.toString());

                if (topic.startsWith('bioscope/status')) {
                    try {
                        const parsedMessage = JSON.parse(message.toString());
                        updateBioscopeOnlineStatus(parsedMessage.bioscopeId, parsedMessage.status);
                    } catch (error) {
                        // console.error('Error parsing MQTT message:', error);
                    }
                }

                if (topic.startsWith('bioscope/livestream')) {
                    try {
                        const parsedMessage = JSON.parse(message.toString());
                        const bioscopeId = extractBioscopeIdFromTopic(topic);

                        updateMuxData(bioscopeId, {
                            streamId: parsedMessage.streamId,
                            status: parsedMessage.status,
                            timestamp: parsedMessage.timestamp,
                            userId: parsedMessage.startedByUserId || null,
                            playbackId: parsedMessage.playbackId || null,
                        });

                        if (parsedMessage.status === 'disconnected') {
                            buttonActionHandler(bioscopeId, false);
                        }

                        if (parsedMessage.startedByUserId !== user_id) {
                            setOnlyViewMode(true);
                        }
                        if (parsedMessage.startedByUserId === null) {
                            const isAnotherUserStreaming = activeCages.some(
                                (cage) => cage.stream?.userId && cage.muxData.userId !== user_id
                            );
                            console.log('isAnotherUserStreaming:', isAnotherUserStreaming);
                            setOnlyViewMode(isAnotherUserStreaming);
                        }
                    } catch (error) {
                        // console.error('Error parsing MQTT message:', error);
                    }
                }
            };
            client.on('message', handleMQTTMessage);
        }

        if (!hasRun3.current && activeCages.length > 0) {
            hasRun3.current = true;
            for (const cage of activeCages) {
                const bioscopeId: number = cage.bioscopeId;
                const { videoStatus: muxStatusTopic, bioscopeStatus: bioscopeStatusTopic } =
                    mqttTopicsVideo(bioscopeId);

                subscribe(muxStatusTopic, { qos: 1 });

                setTimeout(() => {
                    subscribe(bioscopeStatusTopic, { qos: 1 });
                }, 1000);
            }
        }
    }, [locationStreams, isConnected, client, activeCages]);

    useEffect(() => {
        return () => {
            for (const cage of activeCagesRef.current) {
                const bioscopeId: number = cage.bioscopeId;
                if (!onlyViewMode) {
                    if (cage.muxData?.status === 'active' || cage.isButtonSelected) {
                        stopStream({ bioscopeId: bioscopeId });
                    }
                }
                unsubscribe(`bioscope/status/${bioscopeId}`);
                unsubscribe(`bioscope/livestream/${bioscopeId}/status`);
            }
        };
    }, []);

    const errorCheck = async (bioscopeId, v) => {
        if (v) {
            const timer = setTimeout(() => {
                const cage = activeCagesRef.current.find((cage) => cage.bioscopeId === bioscopeId);

                if (cage.muxData?.status !== 'active' && cage.isButtonSelected) {
                    disableStream({ bioscopeId: bioscopeId });
                    buttonActionHandler(bioscopeId, false);
                }
            }, 60000);
        }
    };

    const tempDisableButton = async (bioscopeId) => {
        setIsButtonDisabled(bioscopeId, true);
        const timer = setTimeout(() => {
            setIsButtonDisabled(bioscopeId, false);
        }, 5000);
    };

    const cageChanged = async (bioscopeId) => {
        const v = activeCages.find((cage) => cage.bioscopeId === bioscopeId);

        buttonActionHandler(bioscopeId);
        updateAllButtonState();

        errorCheck(bioscopeId, !v.isButtonSelected);
        tempDisableButton(bioscopeId);
        tempDisableButton(0);
    };

    const allCagesChanged = async () => {
        const v = !allButtonState.isSelected;

        for (const cage of activeCages) {
            if (cage.online && !cage.isOutOfQuota) {
                buttonActionHandler(cage.bioscopeId, v);
                errorCheck(cage.bioscopeId, v);
                tempDisableButton(cage.bioscopeId);
            }
        }
        updateAllButtonState();
        tempDisableButton(0);
    };

    const showSpinner: boolean = activeCages
        .filter((cage) => cage.online && !cage.isOutOfQuota)
        .some((cage) => cage.isButtonSelected && cage.muxData?.status !== 'active');

    let showHello: boolean = activeCages
        .filter((cage) => cage.online && !cage.isOutOfQuota)
        .every((cage) => !cage.isButtonSelected);

    if (onlyViewMode) {
        showHello = !activeCages
            .filter((cage) => cage.online && !cage.isOutOfQuota)
            .some((cage) => cage.muxData?.status === 'active');
    }

    return (
        <>
            <section>
                <PageTitleRow title={t('VideoStream.Video')} />
                <ChipsButtons
                    cageChangedHandler={cageChanged}
                    allCagesChangedHandler={allCagesChanged}
                    activeCagesButtons={activeCages}
                    allButton={allButtonState}
                    onlyViewMode={onlyViewMode}
                />
                {showSpinner && <Spinner t={t} />}
                {<br />}
            </section>

            <UmerBox>
                <section>
                    <Screens activeCages={activeCages} onlyViewMode={onlyViewMode} />
                    {showHello && <Hello t={t} />}
                </section>
            </UmerBox>
        </>
    );
};

const ChipsButtons = ({
    cageChangedHandler,
    allCagesChangedHandler,
    activeCagesButtons,
    allButton,
    onlyViewMode,
}) => {
    const { t } = useTranslation();

    if (onlyViewMode) {
        return <Viewmode t={t} />;
    }

    return (
        <div className={styles.buttonWrapper}>
            <button
                disabled={allButton.isDisabled}
                onClick={() => allCagesChangedHandler()}
                data-isselected={allButton.isSelected}>
                {'All'}
            </button>
            {activeCagesButtons?.map((cage) => (
                <button
                    key={cage.cageId}
                    disabled={cage.isButtonDisabled || cage.isOutOfQuota}
                    onClick={() => cageChangedHandler(cage.bioscopeId)}
                    data-isselected={cage.isButtonSelected}>
                    {!cage.online && !cage.isOutOfQuota && (
                        <GenericIcon icon={icons.OfflineIconSVG} size={20} />
                    )}
                    {cage.isOutOfQuota && (
                        <GenericIcon icon={icons.TimeDurationIconSVG} size={20} />
                    )}
                    {cage.cageName}
                </button>
            ))}
        </div>
    );
};

const Screens = ({ activeCages, onlyViewMode }) => {
    return (
        <div className={styles.videoGrid}>
            {activeCages?.map((cage) => {
                if (cage.isButtonSelected || (cage.muxData?.status === 'active' && onlyViewMode)) {
                    return (
                        <VideoBox
                            cageName={cage?.cageName}
                            key={cage?.bioscopeId}
                            userId={cage?.muxData?.userId}
                            videoState={cage?.muxData?.status || ''}
                            videoId={`video-id-${cage?.bioscopeId}`}
                            videoSrc={cage?.muxData?.playbackId}
                            timeRemaining={300}
                        />
                    );
                }
            })}
        </div>
    );
};

const Hello = ({ t }) => (
    <div className={styles.videoGridEmpty}>
        <img src={videoStreamImage} alt="" />
        <div className={styles.textContainer}>
            <div className={styles.headerContainer}>
                <VideoIcon height={30} width={30} color={OptoColors.OceanGreen} />
                <h2>{t('VideoStream.live_video_secion_title')}</h2>
            </div>
            <p>
                {t('VideoStream.live_vide_secion_part_1')}{' '}
                <b>{t('VideoStream.live_vide_secion_part_2')}</b>{' '}
                {t('VideoStream.live_vide_secion_part_3')}
            </p>
            <p>
                {t('VideoStream.live_vide_secion_part_4')}{' '}
                <b>{t('VideoStream.live_vide_secion_part_5')}</b>{' '}
                {t('VideoStream.live_vide_secion_part_6')}
            </p>
            <p>
                <b>{t('VideoStream.live_vide_secion_part_7')}</b>{' '}
                {t('VideoStream.live_vide_secion_part_8')}
            </p>
            <p className={styles.contactMsg}>
                <GenericIcon icon={icons.MailIconSVG} size={20} />
                {t('VideoStream.live_vide_secion_part_9')}{' '}
                <a href="mailto:support@optoscale.no">
                    {t('VideoStream.live_vide_secion_part_10')}
                </a>{' '}
                {t('VideoStream.live_vide_secion_part_11')}
            </p>
        </div>
    </div>
);

const Viewmode = ({ t }) => (
    <div className={styles.videoLoadingTopBaner}>
        <VideoLoadingTopBaner isLoading={false}>
            <div className={styles.videoLoadingTextWithIcon}>
                <ViewModeIcon height={24} width={24} color={OptoColors.OceanGreen} />
                <h5>{t('VideoStream.viewMode')}</h5>
            </div>
            <div className={styles.contentWrapper}>{t('VideoStream.viewModeDescription')}</div>
        </VideoLoadingTopBaner>
    </div>
);

const Spinner = ({ t }) => (
    <div className={styles.videoLoadingTopBaner}>
        <VideoLoadingTopBaner isLoading={true}>
            <div className={styles.videoLoadingTextWithIcon}>
                <GlassHourIcon height={24} width={24} color={OptoColors.OceanGreen} />
                <h5>{t('VideoStream.loadingLiveStream')}</h5>
            </div>
            <div className={styles.contentWrapper}>{t('VideoStream.loadingTextDescription')}</div>
        </VideoLoadingTopBaner>
    </div>
);

export default LocationVideoContainer;
export { LocationVideoContainer };
