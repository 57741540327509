import { DownloadCsvXlsx } from '@/components/molecules/DownloadCsvXlsx';
import type { PDFData } from '@/routes/Client/Location/pages/LocationLiceReport/PdfReport/LocationLicePDFContent';

import { DownloadPDF } from '../DownloadPDF/DownloadPDFButton';

export interface DownloadOptionsProps {
    data: any;
    columns?: string[];
    nameToInclude?: string;
    pdfButtonLocation?: boolean;
    pdfButtonRegion?: boolean;
    clientName?: string;
    weekNumber?: number;
}

const DownloadOptions = ({
    data,
    columns,
    nameToInclude,
    pdfButtonLocation = false,
    pdfButtonRegion = false,
    clientName,
    weekNumber,
}: DownloadOptionsProps) => {
    return (
        <>
            <DownloadCsvXlsx
                label="CSV"
                data={data}
                COLUMNS={columns}
                nameToInclude={nameToInclude}
            />
            <DownloadCsvXlsx
                label="XLSX"
                data={data}
                COLUMNS={columns}
                nameToInclude={nameToInclude}
            />
            {pdfButtonLocation && (
                <DownloadPDF
                    buttonText={'PDF'}
                    docGenerator={async () => {
                        const { LocationLicePDFContent } = await import(
                            '@/routes/Client/Location/pages/LocationLiceReport/PdfReport/LocationLicePDFContent'
                        );
                        return (
                            <LocationLicePDFContent
                                clientName={clientName ?? ''}
                                allData={data as PDFData[]}
                                weekNumber={weekNumber?.toString() ?? ''}
                            />
                        );
                    }}
                    fileName={nameToInclude ?? `LiceReport${weekNumber}`}
                />
            )}
            {pdfButtonRegion && (
                <DownloadPDF
                    buttonText={'PDF'}
                    docGenerator={async () => {
                        const { RegionLicePDFContent } = await import(
                            '@/routes/Client/Overview/RegionLiceReport/PdfReport/RegionLicePDFContent'
                        );
                        return (
                            <RegionLicePDFContent
                                clientName={clientName ?? ''}
                                allData={data as PDFData[]}
                                weekNumber={weekNumber?.toString() ?? ''}
                            />
                        );
                    }}
                    fileName={nameToInclude ?? `LiceReport${weekNumber}`}
                />
            )}
        </>
    );
};

export default DownloadOptions;
export { DownloadOptions };
