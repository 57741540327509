const dropDownObjects = [
    {
        value: 'false_lice',
        label: 'A detection is not a louse',
        type: 'lice',
        onlyOptoScaleAdmin: false,
    },
    {
        value: 'missed_lice',
        label: 'A louse is not detected',
        type: 'lice',
        onlyOptoScaleAdmin: false,
    },
    {
        value: 'confused_lice',
        label: 'A louse has wrong type',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
    {
        value: 'false_wound',
        label: 'A detection is not a wound',
        type: 'health',
        onlyOptoScaleAdmin: false,
    },
    {
        value: 'confused_wound',
        label: 'A wound has incorrect type',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
    {
        value: 'future_treasure',
        label: 'Interesting for future development',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
    {
        value: 'gill_shortening',
        label: 'Gill shortening',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
    {
        value: 'interesting_head',
        label: 'Interesting head',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
    {
        value: 'deformed_body',
        label: 'Deformed body',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
    {
        value: 'fin_damage',
        label: 'Fin damage',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
    {
        value: 'interesting_louse',
        label: 'Interesting louse',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
    {
        value: 'interesting_wound',
        label: 'Interesting wound',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
    {
        value: 'interesting_scale_loss',
        label: 'Interesting scale loss',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
    {
        value: 'missed_wound',
        label: 'A wound is not detected',
        type: 'health',
        onlyOptoScaleAdmin: false,
    },
    {
        value: 'interesting_haemorrhages',
        label: 'Interesting haemorrhages',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
    {
        value: 'interesting_maturation',
        label: 'Interesting maturation',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
    {
        value: 'interesting_emaciation',
        label: 'Interesting emaciation',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
    {
        value: 'interesting_snout_wound',
        label: 'Interesting snout wound',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
    {
        value: 'interesting_jaw_deformity',
        label: 'Interesting jaw deformity',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
    {
        value: 'interesting_cataract',
        label: 'Interesting cataract',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
    {
        value: 'interesting_eye_damage',
        label: 'Interesting eye damage',
        type: 'internal',
        onlyOptoScaleAdmin: true,
    },
];

const getLabelByValue = (value: string) => {
    return dropDownObjects.find((obj) => obj.value === value)?.label;
};

export default dropDownObjects;
export { dropDownObjects, getLabelByValue };
